@import "partials/mixins";
@import "partials/params";

#Contact {

  & .left-contact {
    padding-left: 95px;

    @media (max-width: 1199.98px) {
      padding-left: 79px;
      padding-right: 50px;
    }
    @media (max-width: 991.98px) {
      padding-left: 56px;
    }
    @media (max-width: 767.98px) {}
    @media (max-width: 575.98px) {}

    & .about_text {
      padding-top: 44px!important;
      padding-right: 0!important;
      padding-left: 0!important;
    }

    & form {
      padding-top: 30px;

      & .contact_form {
        margin-bottom: 0 !important;
        overflow-y: hidden;
        position: relative;

        & .my-contact_input {
          position: absolute;
          top: -90px;
          left: 0;
        }

        & .form-control {
          display: block;
          width: 100%;
          height: calc(1.5em + .75rem + 2px);
          padding: .375rem .75rem;
          margin-bottom: 30px;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: $grey;
          background-color: $white;
          background-clip: padding-box;
          border: 1px solid $fiolet;
          border-radius: 0;
          transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

          &:focus {
            box-shadow: none;
            border: 1px solid $fiolet;
          }

          &::-webkit-input-placeholder,
          &:-moz-placeholder,
          &::-moz-placeholder,
          &:-ms-input-placeholder,
          &::input-placeholder{
            font-weight: 300;
            color: red;
          }

          &::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.5s ease;}
          &::-moz-placeholder                {opacity: 1; transition: opacity 0.5s ease;}
          &:-moz-placeholder                 {opacity: 1; transition: opacity 0.5s ease;}
          &:-ms-input-placeholder            {opacity: 1; transition: opacity 0.5s ease;}
          &:focus::-webkit-input-placeholder {opacity: 0.3; transition: opacity 0.5s ease;}
          &:focus::-moz-placeholder          {opacity: 0.3; transition: opacity 0.5s ease;}
          &:focus:-moz-placeholder           {opacity: 0.3; transition: opacity 0.5s ease;}
          &:focus:-ms-input-placeholder      {opacity: 0.3; transition: opacity 0.5s ease;}

          &.is-valid,
          &:valid{
            border-color: $fiolet;
            padding-right: calc(1.5em + .75rem);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%238583e1' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(.375em + .1875rem) center;
            background-size: calc(.75em + .375rem) calc(.75em + .375rem);
          }

          &.is-invalid {
            border-color: #DC3545;
          }
        }

        & textarea.form-control {
          height: auto;
          margin-bottom: 0;
        }
      }

      & .contact_button {
        width: 239px;
        height: 55px;
        background: $fiolet;
        color: $white;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        border: none;
        outline: none;
        margin-top: 26px;
        transition: all 200ms ease;
        margin-bottom: 100px;

        &:hover {
          background: $darkfiolet;
        }
      }
    }
  }



  & #YMapsID1 {
    padding-top: 50px;
    height: 100%;
  }

  & #YMapsID2 {
    padding-top: 30px;
    height: 400px;
  }
  & [class*="ymaps-2"][class*="-ground-pane"] {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
  }

  & #messenger {
    display: block;
    text-align: center;
    margin: auto;
    width: 50vw;
    position: fixed;
    top: 45%;
    left: 25vw;
    z-index: 9999;

    @media (max-width: 1199.98px) {}
    @media (max-width: 991.98px) {
      width: 80vw !important;
      left: 10vw !important;
    }
    @media (max-width: 767.98px) {
      width: 90vw !important;
      left: 5vw !important;
    }
    @media (max-width: 575.98px) {
      width: 96vw !important;
      left: 2vw !important;
    }
  }

  & .formError{
    border:1px solid red !important;
  }
}

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/
