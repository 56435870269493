@import "partials/mixins";
@import "partials/params";

#start {

  & .foto-bg {
    padding-left: 0;
    padding-right: 0;

    & .my-header {
      background-image: url("../images/my-foto-new.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: calc(100vh - 54px);
    }
  }
}