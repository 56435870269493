@import "partials/mixins";
@import "partials/params";

.fancybox-container {
  z-index: 9000;
}
/* --------------------------- */
.fancybox-bg {
  background: #ffffff;
  opacity: 1;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.47, 0, .74, .71);
}
.fancybox-is-open .fancybox-bg {
  opacity: 1;
  transition-timing-function: cubic-bezier(.22, .61, .36, 1);
}
/* --------------------------- */
.fancybox-inner {}
/* --------------------------- */
.container-fluid .fancybox-infobar {
  font-family: 'Comfortaa', cursive;
  position: absolute;
  right: 0;
  bottom: 0;
  color: $black;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  user-select: none;
}
/* --------------------------- */
.fancybox-show-infobar .fancybox-toolbar {
  position: absolute;
  right: 0;
  top: 0;
}
/* --------------------------- */
.fancybox-navigation {}
.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  padding: 30px 0 0 50px;
}
.fancybox-navigation .fancybox-button--arrow_right {
  padding: 30px 50px 0 0;
  right: 0;
}
.fancybox-navigation .fancybox-button--arrow_left:hover,
.fancybox-navigation .fancybox-button--arrow_right:hover {
  opacity: .5;
}
.prev-but {
  width: 30px;
  height: 60px;
  background-image: url("../images/prev.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
}
.next-but {
  width: 30px;
  height: 60px;
  background-image: url("../images/next.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
}
.fancybox-button {
  background: none;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}
.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc;
}
.fancybox-button:hover {
  color: #000;
}
/* --------------------------- */
.fancybox-stage {}

/* --------------------------- */
.fancybox-caption {
  background: $white !important;

  & .fancybox-caption__body {
    color: $black;
    padding-bottom: 50px;

    & .opis {
      font-size: 16px;
      opacity: .5;
    }
  }
}
.fancybox-progress {
  background: $fiolet;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}


/*
.fancybox-container {
  z-index: 9000;
  & .fancybox-bg {
    background: $white;
    opacity: 1;
    transition-duration: inherit;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.47, 0, .74, .71);
    &.fancybox-is-open {
      opacity: 1;
      transition-timing-function: cubic-bezier(.22, .61, .36, 1);
    }
  }
  & .fancybox-inner {
    & .fancybox-infobar {
      font-family: 'Comfortaa', cursive;
      color: $fiolet;
      font-size: 13px;
      height: 44px;
      line-height: 44px;
      min-width: 44px;
      padding: 0 10px;
      pointer-events: none;
      user-select: none;
      & span {}
    }
    & .fancybox-toolbar {
      & .fancybox-button {
        display: inline-block;
        height: 44px;
        width: 44px;
        margin: 0;
        padding: 10px;
        background: none;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        cursor: pointer;
        position: relative;
        transition: color .2s;
        vertical-align: top;
        visibility: inherit;
        & .prev-but {
          width: 30px;
          height: 60px;
          background-image: url("../images/prev.svg");
          background-repeat: no-repeat;
          background-position: 0 0;
        }
        & .next-but {
          width: 30px;
          height: 60px;
          background-image: url("../images/next.svg");
          background-repeat: no-repeat;
          background-position: 0 0;
        }
        &:visited,
        &:link {
          color: $grey;
        }
        &:hover {
          color: $black;
        }
      }
    }
    & .fancybox-navigation {
      & .fancybox-button--arrow_left {
        left: 0;
        padding: 30px 0 0 50px;
        &:hover {
          opacity: .5;
        }
      }
      & .fancybox-button--arrow_right {
        padding: 30px 50px 0 0;
        right: 0;
        &:hover {
          opacity: .5;
        }
      }
    }
    & .fancybox-stage {}
    & .fancybox-caption {
      background: $white !important;
      & .fancybox-caption__body {
        color: $black;
        padding-bottom: 50px;
        & .opis {
          font-size: 16px;
          opacity: .5;
        }
      }
    }
  }
  & .fancybox-progress {
    background: $fiolet;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: 0;
    transition-property: transform;
    transition-timing-function: linear;
    z-index: 99998;
  }
}*/
