@import "partials/mixins";
@import "partials/params";
/*

#Services {
  padding-top: 0px;
  padding-bottom: 100px;

  & .bg-right {
    position: relative;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(239, 239, 239, 1) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#efefef', GradientType=1); !* IE6-9 *!
    padding-top: 0px !important;
    padding-right: 50px;
    padding-bottom: 100px;
    padding-left: 0px;
  }
}*/
