@charset "UTF-8";
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none;
}

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px;
}
img.foto-my {
  width: 100%;
}
.bg-right {
  position: relative;
  background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(239,239,239,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 ); /* IE6-9 */
  padding-left: 0;
  padding-right: 0;
}
.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: $grey;
  line-height: 0.75;

  @media (max-width: 1199.98px) {
    padding-left: 60px;
  }
  @media (max-width: 991.98px) {
    padding-left: 40px;
    font-size: 26px;
  }
  @media (max-width: 767.98px) {
    padding-left: 20px;
    font-size: 24px;
  }
  @media (max-width: 575.98px) {}
}
.main_title {
  font-size: 80px;
  font-weight: 800;
  color: $darkfiolet;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px;

  @media (max-width: 1199.98px) {
    font-size: 65px;
    padding-left: 60px;
  }
  @media (max-width: 991.98px) {
    font-size: 60px;
    padding-left: 40px;
  }
  @media (max-width: 767.98px) {
    font-size: 50px;
    padding-left: 20px;
  }
  @media (max-width: 575.98px) {}
}
.about_title {
  font-size: 30px;
  font-weight: 700;
  color: $darkfiolet;
  padding-top: 90px;
  padding-left: 80px;

  @media (max-width: 1199.98px) {
    padding-left: 60px;
    font-size: 28px;
  }
  @media (max-width: 991.98px) {
    padding-left: 40px;
    font-size: 26px;
  }
  @media (max-width: 767.98px) {
    padding-left: 20px;
    font-size: 24px;
  }
  @media (max-width: 575.98px) {}
}
.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5;

  @media (max-width: 1199.98px) {
    padding-left: 60px;
  }
  @media (max-width: 991.98px) {
    padding-left: 40px;
  }
  @media (max-width: 767.98px) {
    padding-left: 20px;
  }
  @media (max-width: 575.98px) {}
}
/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */