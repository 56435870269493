@import "partials/mixins";
@import "partials/params";

#Testimonials {

  & .testimonials {
    width: 100%;
    padding-top: 75px;

    & .test_slider_container {
      padding-left: 29px;
      padding-right: 30px;
      overflow: hidden;

      & .test_item {
        width: 100%;
        background: $white;
        border: 1px solid $grey;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 50px;
        padding-right: 50px;
        transition: all 200ms ease;

        &::after {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          border: solid 2px $fiolet;
          transition: all 200ms ease;
          content: '';
          visibility: hidden;
          opacity: 0;
        }

        &:hover {
          box-shadow: 0px 24px 29px rgba(0,0,0,0.23);

          &::after {
            visibility: visible;
            opacity: 1;
          }
        }

        & .test_title {
          font-size: 20px;
          font-weight: 700;
          color: $darkfiolet;
        }

        & .test_text {
          margin-top: 24px;
        }

        & .test_image {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          overflow: hidden;
          margin-top: 42px;

          & img {
            max-width: 100%;
          }
        }

        & .test_info {
          font-size: 15px;
          font-weight: 600;
          margin-top: 25px;

          & a {
            color: $fiolet;
          }

          & span {
            color: $grey;
            margin-left: 8px;
          }
        }
      }

      & .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: 45px;
      }
      & .owl-theme .owl-dots .owl-dot span {
        width: 8px;
        height: 8px;
        background: #d5c6c6;
        margin-left: 2px;
        margin-right: 3px;
      }
      & .owl-theme .owl-dots .owl-dot.active span {
        background: $fiolet;
      }
      & .owl-theme .owl-dots .owl-dot:hover span {
        background: $fiolet;
      }
    }
  }
}
.owl-carousel .owl-stage-outer {
  overflow: visible;

  & .owl-stage {

    & .owl-item {
      padding-bottom: 50px;
    }
  }
}
/*
#Testimonials {
  padding-top: 0px;

  & .bg-right {
    position: relative;
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(239,239,239,1) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 ); !* IE6-9 *!
    padding-top: 20px;
    padding-right: 50px;
    padding-bottom: 30px;
    padding-left: 80px;
  }

  & .main_subtitle {
    padding-top: 130px;
  }

  & .main_title {
    padding-bottom: 100px;
  }

  & .testimonials {
    width: 100%;

    & .test_slider_container {
      padding-left: 29px;
      padding-right: 30px;
      overflow: hidden;

      & .test_item {
        width: 100%;
        background: #f7f8fc;
        padding-top: 74px;
        padding-bottom: 74px;
        padding-left: 46px;
        padding-right: 40px;
        transition: all 200ms ease;

        &::after {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          border: solid 2px $fiolet;
          transition: all 200ms ease;
          content: '';
          visibility: hidden;
          opacity: 0;
        }

        &:hover {
          box-shadow: 0px 24px 29px rgba(0,0,0,0.23);

          &::after {
            visibility: visible;
            opacity: 1;
          }
        }

        & .test_title {
          font-size: 20px;
          font-weight: 700;
          color: $darkfiolet;
        }

        & .test_text {
          margin-top: 24px;
        }

        & .test_image {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          overflow: hidden;
          margin-top: 42px;

          & img {
            max-width: 100%;
          }
        }

        & .test_info {
          font-size: 15px;
          font-weight: 600;
          margin-top: 25px;

          & a {
            color: $fiolet;
          }

          & span {
            color: $grey;
            margin-left: 8px;
          }
        }
      }

      & .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: 45px;
      }
      & .owl-theme .owl-dots .owl-dot span {
        width: 8px;
        height: 8px;
        background: #d5c6c6;
        margin-left: 2px;
        margin-right: 3px;
      }
      & .owl-theme .owl-dots .owl-dot.active span {
        background: $fiolet;
      }
      & .owl-theme .owl-dots .owl-dot:hover span {
        background: $fiolet;
      }
    }
  }
}

.owl-carousel .owl-stage-outer {
  overflow: visible;
}*/
