@import "partials/mixins";
@import "partials/params";

#Experience {

  & .info {
    padding-left: 80px;
    padding-right: 50px;
    padding-bottom: 50px;

    @media (max-width: 1199.98px) {}
    @media (max-width: 991.98px) {
      padding-left: 60px;
    }
    @media (max-width: 767.98px) {
      padding-left: 40px;
    }
    @media (max-width: 575.98px) {}

    & .main_subtitle,
    & .main_title,
    & .about_text {
      padding-left: 0;
      padding-right: 0;
    }

    & .main_title {
      padding-bottom: 50px;
    }

    & .about_text {
      padding-top: 50px;
      opacity: .5;
    }

    & .experience {
      width: 100%;
      padding-bottom: 65px;

      & .exp_item:not(:last-child) {
        margin-bottom: 53px;
      }

      & .exp_time {
        font-size: 24px;
        font-weight: 700;
        color: $fiolet;
        line-height: 0.85;
        text-align: right;

        @media (max-width: 1199.98px) {}
        @media (max-width: 991.98px) {
          text-align: left;
          padding-bottom: 10px;
        }
        @media (max-width: 767.98px) {}
        @media (max-width: 575.98px) {}
      }

      & .exp_title {
        font-size: 24px;
        font-weight: 700;
        color: $darkfiolet;
        line-height: 0.95;

        @media (max-width: 1199.98px) {}
        @media (max-width: 991.98px) {}
        @media (max-width: 767.98px) {
          font-size: 22px;
        }
        @media (max-width: 575.98px) {
          font-size: 20px;
        }
      }

      & .exp_subtitle {
        font-size: 16px;
        font-weight: 600;
        color: $grey;
        margin-top: 7px;
      }

      & .exp_text {
        padding-top: 10px;
        padding-bottom: 40px;
        opacity: .5;
      }
    }
  }
}

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/

