@import "partials/mixins";
@import "partials/params";

#Skills {

  & .my-skill-photo {
    background-image: url("../images/730474690.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 200px;
    animation: animBack 10s ease infinite;
  }

  & .info {
    padding-left: 80px;
    padding-right: 50px;
    padding-bottom: 50px;

    @media (max-width: 1199.98px) {}
    @media (max-width: 991.98px) {
      padding-left: 60px;
    }
    @media (max-width: 767.98px) {
      padding-left: 40px;
    }
    @media (max-width: 575.98px) {}

    & .main_subtitle,
    & .main_title,
    & .about_text {
      padding-left: 0;
      padding-right: 0;
    }

    & .about_text {
      padding-top: 50px;
    }
  }

  & .my-skills {
    padding-top: 50px;

    & .skills {
      width: 100%;

      & .progress_bars {
        & .pb_item {
          width: 100%;
          padding-bottom: 20px;

          & .p_bar_title {
            font-size: 14px;
            color: $darkfiolet;
            font-weight: 500;
            line-height: 0.75;
            margin-bottom: 10px;
            z-index: 1000;
          }

          & .skill_bars {
            width: 100%;
            height: 5px;
            position: relative;

            & svg {
              position: absolute;
              top: 0;
              left: 0;
            }

            & .progressbar-text {
              text-align: right;
              font-size: 13px;
              color: $darkfiolet;
              font-weight: 500;
              position: absolute;
              top: -26px !important;
            }
          }
        }
      }
    }
  }

  & .milestones {
    width: 100%;
    margin-top: 93px;

    & .milestone {
      width: 25%;
      float: left;

      @media (max-width: 1199.98px) {}
      @media (max-width: 991.98px) {}
      @media (max-width: 650px) {
        width: 50%;
        padding-bottom: 30px;
      }
      @media (max-width: 450px) {
        width: 100%;
      }

      & .milestone_icon {}

      & .milestone_counter {
        font-size: 36px;
        font-weight: 800;
        color: $darkfiolet;
        margin-top: 6px;
      }

      & .milestone_text {
        font-size: 16px;
        font-weight: 500;
        color: $grey;
        margin-top: -1px;
      }
    }

    & .milestone.left {
      width: 100%;
      padding-bottom: 30px;
    }
  }
}
@keyframes animBack {
  0% {background-position: center bottom}
  50% {background-position: center top}
  100% {background-position: center bottom}
}

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/
