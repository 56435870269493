@charset "UTF-8";
/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
#about .gen-info {
  background: #100f3a;
  padding: 50px 30px; }
  @media (max-width: 1199.98px) {
    #about .gen-info {
      padding: 45px 25px; } }
  @media (max-width: 991.98px) {
    #about .gen-info {
      padding: 40px 20px; } }
  @media (max-width: 767.98px) {
    #about .gen-info {
      padding: 30px 15px; } }
  #about .gen-info .general_info_title {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    line-height: 1.2;
    text-align: center; }
    @media (max-width: 575.98px) {
      #about .gen-info .general_info_title {
        font-size: 24px; } }
  #about .gen-info .general_info_list {
    margin-top: 34px; }
    @media (max-width: 575.98px) {
      #about .gen-info .general_info_list {
        display: block;
        margin: 30px auto;
        padding-left: 50px;
        padding-right: 50px; } }
    #about .gen-info .general_info_list li .general_info_icon {
      width: 21px;
      height: 21px;
      margin-right: 24px; }
      #about .gen-info .general_info_list li .general_info_icon img {
        max-width: 100%; }
    #about .gen-info .general_info_list li .general_info_text {
      font-size: 14px;
      color: #7a798c;
      line-height: 1.2; }
      @media (max-width: 575.98px) {
        #about .gen-info .general_info_list li .general_info_text {
          font-size: 16px; } }
      #about .gen-info .general_info_list li .general_info_text a {
        color: #7a798c;
        transition: all 200ms ease; }
        #about .gen-info .general_info_list li .general_info_text a:hover {
          color: #fff; }
      #about .gen-info .general_info_list li .general_info_text span {
        color: #fff; }
    #about .gen-info .general_info_list li:not(:last-child) {
      margin-bottom: 15px; }

#about .bg-right .header_button {
  display: none;
  position: fixed;
  right: 30px;
  top: 83px;
  background: #8583e1;
  transition: all 200ms ease;
  z-index: 2000; }
  #about .bg-right .header_button:hover {
    background: #100f3a; }
  #about .bg-right .header_button a {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 55px;
    color: #fff;
    padding-left: 22px;
    padding-right: 76px; }
    #about .bg-right .header_button a:hover {
      color: #fff; }
  #about .bg-right .header_button > div {
    position: absolute;
    top: 0;
    right: 0;
    width: 55px;
    height: 55px;
    background: #100f3a;
    pointer-events: none; }

#about .content {
  margin: 0 auto;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px; }
  @media (max-width: 767.98px) {
    #about .content {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (max-width: 575.98px) {
    #about .content {
      padding-left: 0px;
      padding-right: 0px; } }
  #about .content:after {
    content: "";
    display: table;
    clear: both; }
  #about .content .circle-container {
    display: block;
    text-align: center;
    float: left;
    width: 33.333%;
    position: relative; }
    @media (max-width: 575.98px) {
      #about .content .circle-container {
        width: 50%; }
        #about .content .circle-container:last-child {
          width: 100%;
          margin-bottom: 45px; } }
    @media (max-width: 410px) {
      #about .content .circle-container {
        width: 100%;
        margin-bottom: 45px; } }
    #about .content .circle-container .loader_title {
      display: block;
      font-size: 16px;
      font-weight: 700;
      color: #100f3a;
      text-transform: uppercase;
      line-height: 0.75;
      padding-top: 20px; }
    #about .content .circle-container .loader_subtitle {
      font-size: 14px;
      font-weight: 500;
      color: #7a798c;
      margin-top: 12px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 50px; }
    #about .content .circle-container .circlestat {
      margin: 0 auto;
      height: 200px; }
  #about .content span.circle-text {
    font-weight: 800; }

/*
class — с помощью значения данного атрибута будет "привязываться" функционал.
data-dimension — диаметр круга.
data-text — текст внутри круга.
data-width — толщина обводки круга.
data-fontsize — размер текста внутри круга.
data-percent — процент заполнения круга.
data-fgcolor — цвет обводки.
data-bgcolor — цвет фонового круга.
data-fill — цвет заливки круга.

@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
#Contact .left-contact {
  padding-left: 95px; }
  @media (max-width: 1199.98px) {
    #Contact .left-contact {
      padding-left: 79px;
      padding-right: 50px; } }
  @media (max-width: 991.98px) {
    #Contact .left-contact {
      padding-left: 56px; } }
  #Contact .left-contact .about_text {
    padding-top: 44px !important;
    padding-right: 0 !important;
    padding-left: 0 !important; }
  #Contact .left-contact form {
    padding-top: 30px; }
    #Contact .left-contact form .contact_form {
      margin-bottom: 0 !important;
      overflow-y: hidden;
      position: relative; }
      #Contact .left-contact form .contact_form .my-contact_input {
        position: absolute;
        top: -90px;
        left: 0; }
      #Contact .left-contact form .contact_form .form-control {
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        margin-bottom: 30px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #7a798c;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #8583e1;
        border-radius: 0;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; }
        #Contact .left-contact form .contact_form .form-control:focus {
          box-shadow: none;
          border: 1px solid #8583e1; }
        #Contact .left-contact form .contact_form .form-control::-webkit-input-placeholder, #Contact .left-contact form .contact_form .form-control:-moz-placeholder, #Contact .left-contact form .contact_form .form-control::-moz-placeholder, #Contact .left-contact form .contact_form .form-control:-ms-input-placeholder, #Contact .left-contact form .contact_form .form-control::input-placeholder {
          font-weight: 300;
          color: red; }
        #Contact .left-contact form .contact_form .form-control::-webkit-input-placeholder {
          opacity: 1;
          transition: opacity 0.5s ease; }
        #Contact .left-contact form .contact_form .form-control::-moz-placeholder {
          opacity: 1;
          transition: opacity 0.5s ease; }
        #Contact .left-contact form .contact_form .form-control:-moz-placeholder {
          opacity: 1;
          transition: opacity 0.5s ease; }
        #Contact .left-contact form .contact_form .form-control:-ms-input-placeholder {
          opacity: 1;
          transition: opacity 0.5s ease; }
        #Contact .left-contact form .contact_form .form-control:focus::-webkit-input-placeholder {
          opacity: 0.3;
          transition: opacity 0.5s ease; }
        #Contact .left-contact form .contact_form .form-control:focus::-moz-placeholder {
          opacity: 0.3;
          transition: opacity 0.5s ease; }
        #Contact .left-contact form .contact_form .form-control:focus:-moz-placeholder {
          opacity: 0.3;
          transition: opacity 0.5s ease; }
        #Contact .left-contact form .contact_form .form-control:focus:-ms-input-placeholder {
          opacity: 0.3;
          transition: opacity 0.5s ease; }
        #Contact .left-contact form .contact_form .form-control.is-valid, #Contact .left-contact form .contact_form .form-control:valid {
          border-color: #8583e1;
          padding-right: calc(1.5em + .75rem);
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%238583e1' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: right calc(.375em + .1875rem) center;
          background-size: calc(.75em + .375rem) calc(.75em + .375rem); }
        #Contact .left-contact form .contact_form .form-control.is-invalid {
          border-color: #DC3545; }
      #Contact .left-contact form .contact_form textarea.form-control {
        height: auto;
        margin-bottom: 0; }
    #Contact .left-contact form .contact_button {
      width: 239px;
      height: 55px;
      background: #8583e1;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      border: none;
      outline: none;
      margin-top: 26px;
      transition: all 200ms ease;
      margin-bottom: 100px; }
      #Contact .left-contact form .contact_button:hover {
        background: #100f3a; }

#Contact #YMapsID1 {
  padding-top: 50px;
  height: 100%; }

#Contact #YMapsID2 {
  padding-top: 30px;
  height: 400px; }

#Contact [class*="ymaps-2"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%); }

#Contact #messenger {
  display: block;
  text-align: center;
  margin: auto;
  width: 50vw;
  position: fixed;
  top: 45%;
  left: 25vw;
  z-index: 9999; }
  @media (max-width: 991.98px) {
    #Contact #messenger {
      width: 80vw !important;
      left: 10vw !important; } }
  @media (max-width: 767.98px) {
    #Contact #messenger {
      width: 90vw !important;
      left: 5vw !important; } }
  @media (max-width: 575.98px) {
    #Contact #messenger {
      width: 96vw !important;
      left: 2vw !important; } }

#Contact .formError {
  border: 1px solid red !important; }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
/*

#Education {
  padding-top: 0px;
  padding-bottom: 100px;

  & .bg-right {
    position: relative;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(239, 239, 239, 1) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#efefef', GradientType=1); !* IE6-9 *!
    padding-top: 0px !important;
    padding-right: 50px;
    padding-bottom: 100px;
    padding-left: 0px;
  }
}*/

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
#Experience .info {
  padding-left: 80px;
  padding-right: 50px;
  padding-bottom: 50px; }
  @media (max-width: 991.98px) {
    #Experience .info {
      padding-left: 60px; } }
  @media (max-width: 767.98px) {
    #Experience .info {
      padding-left: 40px; } }
  #Experience .info .main_subtitle,
  #Experience .info .main_title,
  #Experience .info .about_text {
    padding-left: 0;
    padding-right: 0; }
  #Experience .info .main_title {
    padding-bottom: 50px; }
  #Experience .info .about_text {
    padding-top: 50px;
    opacity: .5; }
  #Experience .info .experience {
    width: 100%;
    padding-bottom: 65px; }
    #Experience .info .experience .exp_item:not(:last-child) {
      margin-bottom: 53px; }
    #Experience .info .experience .exp_time {
      font-size: 24px;
      font-weight: 700;
      color: #8583e1;
      line-height: 0.85;
      text-align: right; }
      @media (max-width: 991.98px) {
        #Experience .info .experience .exp_time {
          text-align: left;
          padding-bottom: 10px; } }
    #Experience .info .experience .exp_title {
      font-size: 24px;
      font-weight: 700;
      color: #100f3a;
      line-height: 0.95; }
      @media (max-width: 767.98px) {
        #Experience .info .experience .exp_title {
          font-size: 22px; } }
      @media (max-width: 575.98px) {
        #Experience .info .experience .exp_title {
          font-size: 20px; } }
    #Experience .info .experience .exp_subtitle {
      font-size: 16px;
      font-weight: 600;
      color: #7a798c;
      margin-top: 7px; }
    #Experience .info .experience .exp_text {
      padding-top: 10px;
      padding-bottom: 40px;
      opacity: .5; }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
.fancybox-container {
  z-index: 9000; }

/* --------------------------- */
.fancybox-bg {
  background: #ffffff;
  opacity: 1;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: 1;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

/* --------------------------- */
/* --------------------------- */
.container-fluid .fancybox-infobar {
  font-family: 'Comfortaa', cursive;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #000;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  user-select: none; }

/* --------------------------- */
.fancybox-show-infobar .fancybox-toolbar {
  position: absolute;
  right: 0;
  top: 0; }

/* --------------------------- */
.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  padding: 30px 0 0 50px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 30px 50px 0 0;
  right: 0; }

.fancybox-navigation .fancybox-button--arrow_left:hover,
.fancybox-navigation .fancybox-button--arrow_right:hover {
  opacity: .5; }

.prev-but {
  width: 30px;
  height: 60px;
  background-image: url("../images/prev.svg");
  background-repeat: no-repeat;
  background-position: 0 0; }

.next-but {
  width: 30px;
  height: 60px;
  background-image: url("../images/next.svg");
  background-repeat: no-repeat;
  background-position: 0 0; }

.fancybox-button {
  background: none;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #000; }

/* --------------------------- */
/* --------------------------- */
.fancybox-caption {
  background: #fff !important; }
  .fancybox-caption .fancybox-caption__body {
    color: #000;
    padding-bottom: 50px; }
    .fancybox-caption .fancybox-caption__body .opis {
      font-size: 16px;
      opacity: .5; }

.fancybox-progress {
  background: #8583e1;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998; }

/*
.fancybox-container {
  z-index: 9000;
  & .fancybox-bg {
    background: $white;
    opacity: 1;
    transition-duration: inherit;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.47, 0, .74, .71);
    &.fancybox-is-open {
      opacity: 1;
      transition-timing-function: cubic-bezier(.22, .61, .36, 1);
    }
  }
  & .fancybox-inner {
    & .fancybox-infobar {
      font-family: 'Comfortaa', cursive;
      color: $fiolet;
      font-size: 13px;
      height: 44px;
      line-height: 44px;
      min-width: 44px;
      padding: 0 10px;
      pointer-events: none;
      user-select: none;
      & span {}
    }
    & .fancybox-toolbar {
      & .fancybox-button {
        display: inline-block;
        height: 44px;
        width: 44px;
        margin: 0;
        padding: 10px;
        background: none;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        cursor: pointer;
        position: relative;
        transition: color .2s;
        vertical-align: top;
        visibility: inherit;
        & .prev-but {
          width: 30px;
          height: 60px;
          background-image: url("../images/prev.svg");
          background-repeat: no-repeat;
          background-position: 0 0;
        }
        & .next-but {
          width: 30px;
          height: 60px;
          background-image: url("../images/next.svg");
          background-repeat: no-repeat;
          background-position: 0 0;
        }
        &:visited,
        &:link {
          color: $grey;
        }
        &:hover {
          color: $black;
        }
      }
    }
    & .fancybox-navigation {
      & .fancybox-button--arrow_left {
        left: 0;
        padding: 30px 0 0 50px;
        &:hover {
          opacity: .5;
        }
      }
      & .fancybox-button--arrow_right {
        padding: 30px 50px 0 0;
        right: 0;
        &:hover {
          opacity: .5;
        }
      }
    }
    & .fancybox-stage {}
    & .fancybox-caption {
      background: $white !important;
      & .fancybox-caption__body {
        color: $black;
        padding-bottom: 50px;
        & .opis {
          font-size: 16px;
          opacity: .5;
        }
      }
    }
  }
  & .fancybox-progress {
    background: $fiolet;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: 0;
    transition-property: transform;
    transition-timing-function: linear;
    z-index: 99998;
  }
}*/

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
.copy {
  display: block;
  margin: auto;
  text-align: center;
  font-size: 14px;
  padding-bottom: 20px;
  padding-top: 20px;
  color: #7a798c;
  opacity: .5; }
  .copy span.small {
    display: none; }
    @media (max-width: 450px) {
      .copy span.small {
        display: inline; } }
  .copy a {
    color: #7a798c; }
  @media (max-width: 450px) {
    .copy span.big {
      display: none; } }

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
.my-navbar {
  z-index: 100;
  padding-top: 0;
  padding-bottom: 0; }
  .my-navbar.cloned {
    position: fixed;
    top: 0;
    margin-top: 0;
    z-index: 1050;
    background: #fff;
    display: none; }
  .my-navbar .logo {
    font-size: 36px;
    font-weight: 800;
    color: #100f3a;
    margin-right: 25px;
    margin-top: -5px;
    transition: all 200ms ease; }
    .my-navbar .logo span {
      color: #8583e1; }
  .my-navbar button.navbar-toggler {
    padding: 10px;
    height: 50px;
    font-weight: 50px; }
    .my-navbar button.navbar-toggler .navbar-toggler-icon {
      background-image: url("../images/hamburger.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 0; }
  .my-navbar #navbar .my-navbar-nav .my-nav-item {
    background: #100f3a;
    transition: all 200ms ease; }
    .my-navbar #navbar .my-navbar-nav .my-nav-item:not(:last-child) {
      margin-right: 1px; }
    .my-navbar #navbar .my-navbar-nav .my-nav-item a.my-nav-link {
      padding: 15px 20px;
      color: #fff; }
    .my-navbar #navbar .my-navbar-nav .my-nav-item:hover .my-nav-link,
    .my-navbar #navbar .my-navbar-nav .my-nav-item.active .my-nav-link {
      background: #8583e1; }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
/*#grid[data-columns]::before {
  content: '4 .column.size-1of4';

  @media (max-width: 1199.98px) {}
  @media (max-width: 991.98px) {
    content: '3 .column.size-1of3';
  }
  @media (max-width: 767.98px) {
    content: '2 .column.size-1of2';
  }
  @media (max-width: 575.98px) {
    content: '1 .column.size-1of1';
  }
}*/
/*.filter-container[data-columns]::before {
  content: '4 .column.size-1of4';
}*/
/*.column { float: left; }
.size-1of1 { width: 100%; }
.size-1of2 { width: 50%; }
.size-1of3 { width: 33.333%; }
.size-1of4 { width: 25%; }*/
#Portfolio .my-portfolio-photo {
  background: url("../images/551746348.jpg") no-repeat 0 0;
  height: 250px;
  background-size: cover; }

#Portfolio .info {
  padding-left: 80px;
  padding-right: 50px;
  padding-bottom: 50px; }
  @media (max-width: 991.98px) {
    #Portfolio .info {
      padding-left: 60px; } }
  @media (max-width: 767.98px) {
    #Portfolio .info {
      padding-left: 40px; } }
  #Portfolio .info .main_subtitle,
  #Portfolio .info .main_title,
  #Portfolio .info .about_text {
    padding-left: 0;
    padding-right: 0; }
  #Portfolio .info .main_subtitle {
    padding-top: 75px; }
  #Portfolio .info .main_title {
    padding-bottom: 30px; }
  #Portfolio .info .portfolio_categories {
    margin-top: 66px; }
    #Portfolio .info .portfolio_categories .portfolio_category {
      display: inline-block;
      padding: 10px 40px 8px 40px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      color: #7a798c;
      transition: all 200ms ease; }
      #Portfolio .info .portfolio_categories .portfolio_category.active, #Portfolio .info .portfolio_categories .portfolio_category:hover {
        background: #8583e1;
        color: #fff;
        font-weight: 700; }

#Portfolio .filtr-item {
  display: block;
  text-align: center;
  padding: 10px;
  z-index: 300;
  float: left;
  width: 25%;
  margin-bottom: 15px; }
  @media (max-width: 991.98px) {
    #Portfolio .filtr-item {
      width: 33%; } }
  @media (max-width: 767.98px) {
    #Portfolio .filtr-item {
      width: 50%; } }
  @media (max-width: 575.98px) {
    #Portfolio .filtr-item {
      width: 100%; } }
  #Portfolio .filtr-item .zoom {
    overflow: hidden; }
    #Portfolio .filtr-item .zoom a {
      display: block; }
    #Portfolio .filtr-item .zoom img {
      max-width: 100%;
      z-index: 10; }
    #Portfolio .filtr-item .zoom:hover a {
      transition: all 200ms ease;
      transform: scale(0.95); }

#Portfolio .figcaption {
  display: none; }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
/*

#Services {
  padding-top: 0px;
  padding-bottom: 100px;

  & .bg-right {
    position: relative;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(239, 239, 239, 1) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#efefef', GradientType=1); !* IE6-9 *!
    padding-top: 0px !important;
    padding-right: 50px;
    padding-bottom: 100px;
    padding-left: 0px;
  }
}*/

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
#Skills .my-skill-photo {
  background-image: url("../images/730474690.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  animation: animBack 10s ease infinite; }

#Skills .info {
  padding-left: 80px;
  padding-right: 50px;
  padding-bottom: 50px; }
  @media (max-width: 991.98px) {
    #Skills .info {
      padding-left: 60px; } }
  @media (max-width: 767.98px) {
    #Skills .info {
      padding-left: 40px; } }
  #Skills .info .main_subtitle,
  #Skills .info .main_title,
  #Skills .info .about_text {
    padding-left: 0;
    padding-right: 0; }
  #Skills .info .about_text {
    padding-top: 50px; }

#Skills .my-skills {
  padding-top: 50px; }
  #Skills .my-skills .skills {
    width: 100%; }
    #Skills .my-skills .skills .progress_bars .pb_item {
      width: 100%;
      padding-bottom: 20px; }
      #Skills .my-skills .skills .progress_bars .pb_item .p_bar_title {
        font-size: 14px;
        color: #100f3a;
        font-weight: 500;
        line-height: 0.75;
        margin-bottom: 10px;
        z-index: 1000; }
      #Skills .my-skills .skills .progress_bars .pb_item .skill_bars {
        width: 100%;
        height: 5px;
        position: relative; }
        #Skills .my-skills .skills .progress_bars .pb_item .skill_bars svg {
          position: absolute;
          top: 0;
          left: 0; }
        #Skills .my-skills .skills .progress_bars .pb_item .skill_bars .progressbar-text {
          text-align: right;
          font-size: 13px;
          color: #100f3a;
          font-weight: 500;
          position: absolute;
          top: -26px !important; }

#Skills .milestones {
  width: 100%;
  margin-top: 93px; }
  #Skills .milestones .milestone {
    width: 25%;
    float: left; }
    @media (max-width: 650px) {
      #Skills .milestones .milestone {
        width: 50%;
        padding-bottom: 30px; } }
    @media (max-width: 450px) {
      #Skills .milestones .milestone {
        width: 100%; } }
    #Skills .milestones .milestone .milestone_counter {
      font-size: 36px;
      font-weight: 800;
      color: #100f3a;
      margin-top: 6px; }
    #Skills .milestones .milestone .milestone_text {
      font-size: 16px;
      font-weight: 500;
      color: #7a798c;
      margin-top: -1px; }
  #Skills .milestones .milestone.left {
    width: 100%;
    padding-bottom: 30px; }

@keyframes animBack {
  0% {
    background-position: center bottom; }
  50% {
    background-position: center top; }
  100% {
    background-position: center bottom; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
#start .foto-bg {
  padding-left: 0;
  padding-right: 0; }
  #start .foto-bg .my-header {
    background-image: url("../images/my-foto-new.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: calc(100vh - 54px); }

/* ======= Color ======= */
/* --------------- /reset.css --------------- */
html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 100%; }

a {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none; }

a.disabled {
  pointer-events: none;
  cursor: default; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, td img {
  vertical-align: top; }

input, select, button, textarea {
  margin: 0;
  font-size: 100%;
  outline: none; }

input[type="checkbox"] {
  vertical-align: bottom; }

input[type="radio"] {
  vertical-align: text-bottom; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

.wrapper {
  padding-right: 0px;
  padding-left: 0px; }

img.foto-my {
  width: 100%; }

.bg-right {
  position: relative;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #efefef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 );
  /* IE6-9 */
  padding-left: 0;
  padding-right: 0; }

.main_subtitle {
  padding-top: 160px;
  padding-left: 80px;
  font-size: 30px;
  font-weight: 500;
  color: #7a798c;
  line-height: 0.75; }
  @media (max-width: 1199.98px) {
    .main_subtitle {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_subtitle {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .main_subtitle {
      padding-left: 20px;
      font-size: 24px; } }

.main_title {
  font-size: 80px;
  font-weight: 800;
  color: #100f3a;
  line-height: 0.85;
  padding-top: 15px;
  padding-left: 80px;
  padding-right: 50px; }
  @media (max-width: 1199.98px) {
    .main_title {
      font-size: 65px;
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .main_title {
      font-size: 60px;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .main_title {
      font-size: 50px;
      padding-left: 20px; } }

.about_title {
  font-size: 30px;
  font-weight: 700;
  color: #100f3a;
  padding-top: 90px;
  padding-left: 80px; }
  @media (max-width: 1199.98px) {
    .about_title {
      padding-left: 60px;
      font-size: 28px; } }
  @media (max-width: 991.98px) {
    .about_title {
      padding-left: 40px;
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    .about_title {
      padding-left: 20px;
      font-size: 24px; } }

.about_text {
  padding-top: 31px;
  padding-left: 80px;
  padding-right: 50px;
  opacity: .5; }
  @media (max-width: 1199.98px) {
    .about_text {
      padding-left: 60px; } }
  @media (max-width: 991.98px) {
    .about_text {
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .about_text {
      padding-left: 20px; } }

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
 */
#Testimonials .testimonials {
  width: 100%;
  padding-top: 75px; }
  #Testimonials .testimonials .test_slider_container {
    padding-left: 29px;
    padding-right: 30px;
    overflow: hidden; }
    #Testimonials .testimonials .test_slider_container .test_item {
      width: 100%;
      background: #fff;
      border: 1px solid #7a798c;
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 50px;
      padding-right: 50px;
      transition: all 200ms ease; }
      #Testimonials .testimonials .test_slider_container .test_item::after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border: solid 2px #8583e1;
        transition: all 200ms ease;
        content: '';
        visibility: hidden;
        opacity: 0; }
      #Testimonials .testimonials .test_slider_container .test_item:hover {
        box-shadow: 0px 24px 29px rgba(0, 0, 0, 0.23); }
        #Testimonials .testimonials .test_slider_container .test_item:hover::after {
          visibility: visible;
          opacity: 1; }
      #Testimonials .testimonials .test_slider_container .test_item .test_title {
        font-size: 20px;
        font-weight: 700;
        color: #100f3a; }
      #Testimonials .testimonials .test_slider_container .test_item .test_text {
        margin-top: 24px; }
      #Testimonials .testimonials .test_slider_container .test_item .test_image {
        width: 53px;
        height: 53px;
        border-radius: 50%;
        overflow: hidden;
        margin-top: 42px; }
        #Testimonials .testimonials .test_slider_container .test_item .test_image img {
          max-width: 100%; }
      #Testimonials .testimonials .test_slider_container .test_item .test_info {
        font-size: 15px;
        font-weight: 600;
        margin-top: 25px; }
        #Testimonials .testimonials .test_slider_container .test_item .test_info a {
          color: #8583e1; }
        #Testimonials .testimonials .test_slider_container .test_item .test_info span {
          color: #7a798c;
          margin-left: 8px; }
    #Testimonials .testimonials .test_slider_container .owl-theme .owl-nav.disabled + .owl-dots {
      margin-top: 45px; }
    #Testimonials .testimonials .test_slider_container .owl-theme .owl-dots .owl-dot span {
      width: 8px;
      height: 8px;
      background: #d5c6c6;
      margin-left: 2px;
      margin-right: 3px; }
    #Testimonials .testimonials .test_slider_container .owl-theme .owl-dots .owl-dot.active span {
      background: #8583e1; }
    #Testimonials .testimonials .test_slider_container .owl-theme .owl-dots .owl-dot:hover span {
      background: #8583e1; }

.owl-carousel .owl-stage-outer {
  overflow: visible; }
  .owl-carousel .owl-stage-outer .owl-stage .owl-item {
    padding-bottom: 50px; }

/*
#Testimonials {
  padding-top: 0px;

  & .bg-right {
    position: relative;
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(239,239,239,1) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#efefef',GradientType=1 ); !* IE6-9 *!
    padding-top: 20px;
    padding-right: 50px;
    padding-bottom: 30px;
    padding-left: 80px;
  }

  & .main_subtitle {
    padding-top: 130px;
  }

  & .main_title {
    padding-bottom: 100px;
  }

  & .testimonials {
    width: 100%;

    & .test_slider_container {
      padding-left: 29px;
      padding-right: 30px;
      overflow: hidden;

      & .test_item {
        width: 100%;
        background: #f7f8fc;
        padding-top: 74px;
        padding-bottom: 74px;
        padding-left: 46px;
        padding-right: 40px;
        transition: all 200ms ease;

        &::after {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          border: solid 2px $fiolet;
          transition: all 200ms ease;
          content: '';
          visibility: hidden;
          opacity: 0;
        }

        &:hover {
          box-shadow: 0px 24px 29px rgba(0,0,0,0.23);

          &::after {
            visibility: visible;
            opacity: 1;
          }
        }

        & .test_title {
          font-size: 20px;
          font-weight: 700;
          color: $darkfiolet;
        }

        & .test_text {
          margin-top: 24px;
        }

        & .test_image {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          overflow: hidden;
          margin-top: 42px;

          & img {
            max-width: 100%;
          }
        }

        & .test_info {
          font-size: 15px;
          font-weight: 600;
          margin-top: 25px;

          & a {
            color: $fiolet;
          }

          & span {
            color: $grey;
            margin-left: 8px;
          }
        }
      }

      & .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: 45px;
      }
      & .owl-theme .owl-dots .owl-dot span {
        width: 8px;
        height: 8px;
        background: #d5c6c6;
        margin-left: 2px;
        margin-right: 3px;
      }
      & .owl-theme .owl-dots .owl-dot.active span {
        background: $fiolet;
      }
      & .owl-theme .owl-dots .owl-dot:hover span {
        background: $fiolet;
      }
    }
  }
}

.owl-carousel .owl-stage-outer {
  overflow: visible;
}*/
