@import "partials/mixins";
@import "partials/params";

/*#grid[data-columns]::before {
  content: '4 .column.size-1of4';

  @media (max-width: 1199.98px) {}
  @media (max-width: 991.98px) {
    content: '3 .column.size-1of3';
  }
  @media (max-width: 767.98px) {
    content: '2 .column.size-1of2';
  }
  @media (max-width: 575.98px) {
    content: '1 .column.size-1of1';
  }
}*/

/*.filter-container[data-columns]::before {
  content: '4 .column.size-1of4';
}*/

/*.column { float: left; }
.size-1of1 { width: 100%; }
.size-1of2 { width: 50%; }
.size-1of3 { width: 33.333%; }
.size-1of4 { width: 25%; }*/

#Portfolio {

  & .my-portfolio-photo {
    background: url("../images/551746348.jpg") no-repeat 0 0;
    height: 250px;
    background-size: cover;
  }

  & .info {
    padding-left: 80px;
    padding-right: 50px;
    padding-bottom: 50px;

    @media (max-width: 1199.98px) {
    }
    @media (max-width: 991.98px) {
      padding-left: 60px;
    }
    @media (max-width: 767.98px) {
      padding-left: 40px;
    }
    @media (max-width: 575.98px) {
    }

    & .main_subtitle,
    & .main_title,
    & .about_text {
      padding-left: 0;
      padding-right: 0;
    }

    & .main_subtitle {
      padding-top: 75px;
    }

    & .main_title {
      padding-bottom: 30px;
    }

    & .portfolio_categories {
      margin-top: 66px;

      & .portfolio_category {
        display: inline-block;
        padding: 10px 40px 8px 40px;
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        color: $grey;
        transition: all 200ms ease;

        &.active,
        &:hover {
          background: $fiolet;
          color: $white;
          font-weight: 700;
        }
      }
    }
  }

  & .filtr-item {
    display: block;
    text-align: center;
    padding: 10px;
    z-index: 300;
    float: left;
    width: 25%;
    margin-bottom: 15px;

    @media (max-width: 1199.98px) {}
    @media (max-width: 991.98px) {
      width: 33%;
    }
    @media (max-width: 767.98px) {
      width: 50%;
    }
    @media (max-width: 575.98px) {
      width: 100%;
    }

    & .zoom {
      overflow: hidden;

      & a {
        display: block;
      }

      & img {
        max-width: 100%;
        z-index: 10;
      }

      &:hover {

        a {
          transition: all 200ms ease;
          transform: scale(.95);
        }
      }
    }
  }
  & .figcaption {
    display: none;
  }
}


/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/