@import "partials/mixins";
@import "partials/params";

#about {

  .gen-info {
    background: $darkfiolet;
    padding: 50px 30px;

    @media (max-width: 1199.98px) {
      padding: 45px 25px;
    }
    @media (max-width: 991.98px) {
      padding: 40px 20px;
    }
    @media (max-width: 767.98px) {
      padding: 30px 15px;
    }

    & .general_info_title {
      font-size: 22px;
      font-weight: 600;
      color: $white;
      line-height: 1.2;
      text-align: center;

      @media (max-width: 575.98px) {
        font-size: 24px;
      }
    }

    & .general_info_list {
      margin-top: 34px;

      @media (max-width: 575.98px) {
        display: block;
        margin: 30px auto;
        padding-left: 50px;
        padding-right: 50px;
      }

      & li {
        & .general_info_icon {
          width: 21px;
          height: 21px;
          margin-right: 24px;

          & img {
            max-width: 100%;
          }
        }

        & .general_info_text {
          font-size: 14px;
          color: $grey;
          line-height: 1.2;

          @media (max-width: 575.98px) {
            font-size: 16px;
          }

          & a {
            color: $grey;
            transition: all 200ms ease;

            &:hover {
              color: $white;
            }
          }

          & span {
            color: $white;
          }
        }
      }

      & li:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  & .bg-right {
    & .header_button {
      display: none;
      position: fixed;
      right: 30px;
      top: 83px;
      background: $fiolet;
      transition: all 200ms ease;
      z-index: 2000;

      &:hover {
        background: $darkfiolet;
      }

      & a {
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: 55px;
        color: $white;
        padding-left: 22px;
        padding-right: 76px;

        &:hover{
          color: $white;
        }
      }

      & > div {
        position: absolute;
        top: 0;
        right: 0;
        width: 55px;
        height: 55px;
        background: $darkfiolet;
        pointer-events: none;
      }
    }
  }

  & .content {
    margin: 0 auto;
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;

    @media (max-width: 767.98px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (max-width: 575.98px) {
      padding-left: 0px;
      padding-right: 0px;
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    & .circle-container {
      display: block;
      text-align: center;
      float: left;
      width:33.333%;
      position: relative;

      @media (max-width: 575.98px) {
        width: 50%;

        &:last-child {
          width: 100%;
          margin-bottom: 45px;
        }
      }
      @media (max-width: 410px) {
        width: 100%;
        margin-bottom: 45px;
      }

      & .loader_title {
        display: block;
        font-size: 16px;
        font-weight: 700;
        color: $darkfiolet;
        text-transform: uppercase;
        line-height: 0.75;
        padding-top: 20px;
      }

      & .loader_subtitle {
        font-size: 14px;
        font-weight: 500;
        color: #7a798c;
        margin-top: 12px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 50px;
      }

      & .circlestat {
        margin: 0 auto;
        height: 200px;
      }
    }

    & span.circle-text {
      font-weight: 800;
    }
  }
}

/*
class — с помощью значения данного атрибута будет "привязываться" функционал.
data-dimension — диаметр круга.
data-text — текст внутри круга.
data-width — толщина обводки круга.
data-fontsize — размер текста внутри круга.
data-percent — процент заполнения круга.
data-fgcolor — цвет обводки.
data-bgcolor — цвет фонового круга.
data-fill — цвет заливки круга.

@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/