@import "partials/mixins";
@import "partials/params";

#nav {}
.my-navbar {
  z-index: 100;
  padding-top: 0;
  padding-bottom: 0;

  &.cloned {
    position: fixed;
    top: 0;
    margin-top: 0;
    z-index: 1050;
    background: $white;
    display: none;
  }

  & .logo {
    font-size: 36px;
    font-weight: 800;
    color: $darkfiolet;
    margin-right: 25px;
    margin-top: -5px;
    transition: all 200ms ease;

    & span {
      color: $fiolet;
    }
  }

  & button.navbar-toggler {
    padding: 10px;
    height: 50px;
    font-weight: 50px;

    & .navbar-toggler-icon {
      background-image: url("../images/hamburger.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 0;
    }
  }

  & #navbar {
    & .my-navbar-nav {
      & .my-nav-item {
        background: $darkfiolet;
        transition: all 200ms ease;

        &:not(:last-child) {
          margin-right: 1px;
        }

        & a.my-nav-link {
          padding: 15px 20px;
          color: $white;
        }

        &:hover .my-nav-link,
        &.active .my-nav-link {
          background: $fiolet;
        }
      }
    }
  }
}

/*
@media (max-width: 1199.98px) {}
@media (max-width: 991.98px) {}
@media (max-width: 767.98px) {}
@media (max-width: 575.98px) {}
*/
