@import "partials/mixins";
@import "partials/params";

.copy {
  display: block;
  margin: auto;
  text-align: center;
  font-size: 14px;
  padding-bottom: 20px;
  padding-top: 20px;
  color: $grey;
  opacity: .5;
  
    & span.small {
      display: none;

      @media (max-width: 450px) {
        display: inline;
      }
    }

  & a {
    color: $grey;
  }

  @media (max-width: 450px) {
    
    & span.big {
      display: none;
    }
  }
}
